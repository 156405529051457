import EntryPoint from 'Engine/EntryPoint';

import gameConfig from '../../assets/config';
import stoneTexture0 from '../../assets/images/part_1.png';
import stoneTexture1 from '../../assets/images/part_2.png';
import stoneTexture2 from '../../assets/images/part_3.png';

import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import MyriadPro from '../../assets/fonts/ubuntu/MYRIADPRO-BOLD.OTF';
import RevansMedium from '../../assets/fonts/Revans/Revans-Medium.ttf';

import desktopAssets from '../../assets/exported/game/AssetsCombined.json';
import falashlibDesktop from '../../assets/exported/game/FlashLib.json';

import mobileAssets from '../../assets/exported/gameMobile/AssetsCombined.json';
import flashlibMobile from '../../assets/exported/gameMobile/FlashLib.json';

// import desktopAssets from '../../assets/exported/game/FlashLibAssets';
// import mobileAssets from '../../assets/exported/gameMobile/FlashLibAssets';

// import jackpotGameAssets from '../../assets/exported/jackpotGame/FlashLibAssets.json'
import jackpotGameAssets from '../../assets/exported/jackpotGame/AssetsCombined.json'
import jackpotGameFlashLib from '../../assets/exported/jackpotGame/FlashLib.json'
// import jackpotGameMobileAssets from '../../assets/exported/jackpotGameMobile/FlashLibAssets.json'
import jackpotGameMobileAssets from '../../assets/exported/jackpotGameMobile/AssetsCombined.json'
import jackpotGameMobileFlashLib from '../../assets/exported/jackpotGameMobile/FlashLib.json'
import eSoundType from '../sounds/eSoundType';

let assets = EntryPoint.mobile ? mobileAssets : desktopAssets;
let flashlib = EntryPoint.mobile ? flashlibMobile : falashlibDesktop;
const jackpotAssets = EntryPoint.mobile ? jackpotGameMobileAssets : jackpotGameAssets;
const jackpotflashlib = EntryPoint.mobile ? jackpotGameMobileFlashLib : jackpotGameFlashLib;

export default {
  assets: [
    {name: 'GameAssets', path: assets, extension: 'json'},
    {name: 'GameFlashLib', path: flashlib, extension: 'json'},
    {name: 'jackpotGameAssets', path: jackpotAssets, extension: 'json'},
    {name: 'jackpotGameFlashLib', path: jackpotflashlib, extension: 'json'},
    {name: 'gameConfig', path: gameConfig, extension: 'json'},
    {name: 'symbols', path: 'animations/symbols/symbols_jackpotter_pro.json', extension: 'json'},
    {name: 'background', path: 'animations/background/bg_animation_hot_and_spicy.json', extension: 'json'},
    {name: 'chest', path: 'animations/chest/skeleton.json', extension: 'json'},
    {name: 'jackpot_panel', path: 'animations/jackpotPanel/skeleton.json', extension: 'json'},
    {name: 'BMFont', path: 'fonts/BMFont/BMFont.xml', extension: 'xml'},
    {name: 'stoneParticle0', path: stoneTexture0, extension: 'png'},
    {name: 'stoneParticle1', path: stoneTexture1, extension: 'png'},
    {name: 'stoneParticle2', path: stoneTexture2, extension: 'png'},
  ],
  delayedAssets: [
    {name: 'antisipator', path: 'animations/antisipator/skeleton_v2.json', extension: 'json'},
    {name: 'bigWin_spine', path: 'animations/bigWins/DWB_b_o_e.json', extension: 'json'},
    {name: 'jackpot', path: 'animations/jackpot/bonus_game.json', extension: 'json'},
  ],
    fonts: [
    { name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    },
    {
      name: 'Myriad Pro',
      path: MyriadPro,
    },{
      name: 'RevansMedium',
      path: RevansMedium,
    },
  ],
  sounds: eSoundType
}
